import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { CoreCommonModule } from '@core/common.module';

import { NavbarComponent } from 'app/layout/components/navbar/navbar.component';
import { NavbarSearchComponent } from 'app/layout/components/navbar/navbar-search/navbar-search.component';

import { NavbarNotificationComponent } from 'app/layout/components/navbar/navbar-notification/navbar-notification.component';
import { TranslateModule } from '@ngx-translate/core';
import { CorePipesModule } from '@core/pipes/pipes.module';
import { NgxPermissionsModule } from 'ngx-permissions';

@NgModule({
  declarations: [NavbarComponent, NavbarSearchComponent, NavbarNotificationComponent],
  imports: [RouterModule, NgbModule, CoreCommonModule, TranslateModule, CorePipesModule, NgxPermissionsModule.forChild()],
  exports: [NavbarComponent]
})
export class NavbarModule { }
