import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../_auth/auth.service';
import { PermissionService } from '../_auth/permission.service';

@Injectable({
  providedIn: 'root',
})
export class PermissionGuardService {
  constructor(
    public router: Router,
    private authService: AuthService,
    private permissionService: PermissionService,
  ) { }

  async canActivate(): Promise<boolean> {
    const permissions = this.authService.getPermission();
    this.permissionService.initPermission({ permissions });
    return true;
  }
}
