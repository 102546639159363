export enum OpdCardStatusIds {
  APPOINTMENT_ID = 1,
  WAIT_EXAMINATION_ID = 2,
  EXAMINATION_ID = 3,
  WAIT_MEDICINE_ID = 4,
  SUCCESS_ID = 5,
  CANCEL_ID = 6,
}

export enum OpdCardStatusName {
  APPOINTMENT = "จองคิว",
  WAIT_EXAMINATION = "รอเข้าตรวจ",
  EXAMINATION = "กำลังตรวจ",
  WAIT_MEDICINE = "รอรับยา",
  SUCCESS = "เสร็จสิ้น",
  CANCEL = "ยกเลิก",
}