<ng-container [ngSwitch]="mode">
  <ng-container *ngSwitchCase="LabelMode.DEFAULT" [ngTemplateOutlet]="default"></ng-container>
  <ng-container *ngSwitchCase="LabelMode.INLINE" [ngTemplateOutlet]="inline"></ng-container>
  <ng-container *ngSwitchCase="LabelMode.NO_LABEL" [ngTemplateOutlet]="input"></ng-container>
</ng-container>

<ng-template #input>
  <div class="demo-inline-spacing" [ngClass]="inputClass">
    <div class="custom-radio-wrapper">
      <ng-container *ngFor="let option of options; let i = index;">
        <div class="custom-control custom-radio">
          <input type="radio" [id]="name+i" [name]="name" [value]="option.id" class="custom-control-input" [formControl]="form" />
          <label class="custom-control-label" [for]="name+i">{{ option.name }}</label>
        </div>
      </ng-container>
    </div>
    <p class="text-muted m-0" *ngIf="options.length === 0">- {{ 'APP.NO_DATA' | translate }} -</p>
  </div>
</ng-template>

<ng-template #default>
  <div class="form-group" [ngClass]="classes">
    <label [for]="name">{{ label | translate }}</label>
    <ng-container [ngTemplateOutlet]="input"></ng-container>
  </div>
</ng-template>

<ng-template #inline>
  <div class="form-group form-group-inline">
    <label [for]="name">{{ label | translate }}</label>
    <ng-container [ngTemplateOutlet]="input"></ng-container>
  </div>
</ng-template>