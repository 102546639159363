<ng-container [ngSwitch]="mode">
  <ng-container *ngSwitchCase="LabelMode.DEFAULT" [ngTemplateOutlet]="default"></ng-container>
  <ng-container *ngSwitchCase="LabelMode.INLINE" [ngTemplateOutlet]="inline"></ng-container>
  <ng-container *ngSwitchCase="LabelMode.NO_LABEL" [ngTemplateOutlet]="nolabel"></ng-container>
</ng-container>

<ng-template #input>
  <ng-container *ngIf="mask">
    <input (focusout)="handleFocusOut()" class="form-control {{ inputClass }}" [ngClass]="{ 'is-invalid': isError, 'is-valid': isValid }" [type]="type" [id]="name" [name]="name" [placeholder]="placeholder | translate" [formControl]="form" [tabindex]="tabindex" [mask]="mask" [dropSpecialCharacters]="dropSpecialCharacters" [thousandSeparator]="thousandSeparator" [patterns]="customPatterns" [minlength]="minlength" [maxlength]="maxlength" />
  </ng-container>
  <ng-container *ngIf="!mask">
    <input (focusout)="handleFocusOut()" class="form-control {{ inputClass }}" [ngClass]="{ 'is-invalid': isError, 'is-valid': isValid }" [type]="type === 'password' ? (showPwd ? 'text' : 'password') : type" [id]="name" [name]="name" [placeholder]="placeholder | translate" [formControl]="form" [tabindex]="tabindex" [autocomplete]="autocomplete" />
    <ng-container *ngIf="type === 'password'" [ngTemplateOutlet]="passwordView"></ng-container>
  </ng-container>
</ng-template>

<ng-template #nolabel>
  <div class="input-group input-group-merge">
    <ng-container [ngTemplateOutlet]="input"></ng-container>
    <ng-container *ngIf="suffix" [ngTemplateOutlet]="inputSuffix"></ng-container>
  </div>
  <ng-container [ngTemplateOutlet]="error"></ng-container>
</ng-template>

<ng-template #default>
  <div class="form-group {{classes}}">
    <ng-container [ngTemplateOutlet]="mainLabel"></ng-container>
    <div class="input-group input-group-merge" [ngClass]="{ disabled: form.disabled, 'require': isRequire }">
      <ng-container [ngTemplateOutlet]="input"></ng-container>
      <ng-container *ngIf="suffix" [ngTemplateOutlet]="inputSuffix"></ng-container>
    </div>
    <ng-container [ngTemplateOutlet]="error"></ng-container>
  </div>
</ng-template>

<ng-template #inline>
  <div class="form-group form-group-inline {{classes}}">
    <ng-container [ngTemplateOutlet]="mainLabel"></ng-container>
    <ng-container [ngTemplateOutlet]="input"></ng-container>
    <ng-container [ngTemplateOutlet]="error"></ng-container>
  </div>
</ng-template>

<ng-template #mainLabel>
  <ng-container *ngIf="labelValue">
    <label [for]="name">{{ label | translate : { no: labelValue } }} <span *ngIf="isRequire" class="text-danger">*</span></label>
  </ng-container>
  <ng-container *ngIf="!labelValue">
    <label [for]="name">{{ label | translate }} <ng-container [ngTemplateOutlet]="subLabel"></ng-container></label>
  </ng-container>
</ng-template>

<ng-template #subLabel>
  <span *ngIf="isRequire" class="text-danger">*</span>
  <span class="{{addonLabelClass}}" *ngIf="addonLabel != '' && addonLabel != '*'">({{ addonLabel | translate }})</span>
  <span class="{{addonLabelClass}}" *ngIf="addonLabel != '' && addonLabel === '*'">{{ addonLabel | translate }}</span>
</ng-template>

<ng-template #inputSuffix>
  <div class="input-group-append">
    <span class="input-group-text text-muted font-th-data" [ngClass]="{ 'is-invalid': isError, 'is-valid': isValid }">{{
      suffix | translate
      }}</span>
  </div>
</ng-template>

<ng-template #error>
  <span class="error" *ngIf="isError && showErrorMessage">{{ errorMessage | translate }}</span>
</ng-template>

<ng-template #passwordView>
  <div class="input-group-append" (click)="showPwd = !showPwd">
    <span class="input-group-text cursor-pointer" [ngClass]="{ 'is-invalid': isError, 'is-valid': isValid }"><i class="feather" [ngClass]="{ 'icon-eye-off': showPwd, 'icon-eye': !showPwd }"></i></span>
  </div>
</ng-template>