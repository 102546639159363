<ng-container [ngSwitch]="mode">
  <ng-container *ngSwitchCase="LabelMode.DEFAULT" [ngTemplateOutlet]="default"></ng-container>
  <ng-container *ngSwitchCase="LabelMode.INLINE" [ngTemplateOutlet]="inline"></ng-container>
  <ng-container *ngSwitchCase="LabelMode.NO_LABEL" [ngTemplateOutlet]="input"></ng-container>
</ng-container>

<ng-template #input>
  <div class="daterange-wrapper {{inputClass}}" [formGroup]="form">
    <div class="input-group">
      <input ngbDatepicker #d1="ngbDatepicker" class="form-control" [placeholder]="placeholder | translate" formControlName="startValue" [firstDayOfWeek]="7" [minDate]="minPDate" [maxDate]="maxPDate">
      <div class="input-group-append">
        <span class="input-group-text cursor-pointer" (click)="d1.toggle()">
          <span [data-feather]="'calendar'" [size]="17"></span>
        </span>
      </div>
    </div>
    <div class="addon">{{ 'APP.TO' | translate }}</div>
    <div class="input-group">
      <input ngbDatepicker #d2="ngbDatepicker" class="form-control" [placeholder]="placeholder | translate" formControlName="endValue" [firstDayOfWeek]="7" [minDate]="minPDate" [maxDate]="maxPDate">
      <div class="input-group-append">
        <span class="input-group-text cursor-pointer" (click)="d2.toggle()">
          <span [data-feather]="'calendar'" [size]="17"></span>
        </span>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #default>
  <div class="form-group {{classes}}">
    <label [for]="name">{{ label | translate }}</label>
    <ng-container [ngTemplateOutlet]="input"></ng-container>
  </div>
</ng-template>

<ng-template #inline>
  <div class="form-group form-group-inline {{classes}}">
    <label [for]="name">{{ label | translate }}</label>
    <ng-container [ngTemplateOutlet]="input"></ng-container>
  </div>
</ng-template>