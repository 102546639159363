import { Component, EventEmitter, forwardRef, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { concat, Observable, of, Subject, Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged, switchMap, take } from 'rxjs/operators';
import { LabelMode } from 'utils/label-mode.enum';
import { GenerateRandom } from 'utils/random';

@Component({
  selector: 'app-select',
  templateUrl: './select.component.html',
  styleUrls: ['./select.component.scss'],
  providers: [{ provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => SelectComponent), multi: true }],
})
export class SelectComponent implements OnInit, OnDestroy, ControlValueAccessor {
  @Input()
  set isDisabled(value: boolean) {
    if (value) {
      this.form.disable();
    } else {
      this.form.enable();
    }
  }

  @Input()
  set error(value: any) {
    this.isError = false;
    if (value) {
      this.isError = true;
    }
  }

  @Input() set labelMode(value: LabelMode) {
    this.mode = value;
  }

  @Input() name = GenerateRandom(6);
  @Input() label: string;
  @Input() addonLabel: string;
  @Input() addonLabelClass: string;
  @Input() placeholder = '';
  @Input() bindLabel = 'name';
  @Input() bindValue = 'id';
  @Input() inputClass: string;
  @Input() classes: string;
  @Input() errorMessage: string = 'VALIDATOR.PLEASE_SELECT';
  @Input() clearable: boolean = true;
  @Input() searchable = true;
  @Input() fetchable = false;
  @Input() isRequire: boolean;

  @Input()
  set options(value: Array<any>) {
    this.options$ = of(value);
  }

  @Output() keywordChange = new EventEmitter();
  @Output() objectChange = new EventEmitter();

  LabelMode = LabelMode;
  mode: LabelMode = LabelMode.DEFAULT;

  form = new FormControl();
  subscription: Subscription;
  searchSubscription: Subscription;

  options$: Observable<Array<any>> = of([]);
  search$ = new Subject();
  isLoading = false;
  isError = false;

  onChange = (value: string) => { };
  onTouched = (value: string) => { };

  constructor() { }

  ngOnInit(): void {
    this.subscription = this.form.valueChanges.pipe(distinctUntilChanged(), switchMap(() => {
      return this.options$;
    })).subscribe(value => {
      if (value) {
        const obj_find = value.find(i => i[this.bindValue] === this.form.value);
        if (obj_find) {
          this.objectChange.emit(obj_find);
        } else {
          this.objectChange.emit(null);
        }
      }
      this.onChange(this.form.value);
    });

    if (this.fetchable) {
      this.setTypeahead();
    }
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
    if (this.searchSubscription) {
      this.searchSubscription.unsubscribe();
    }
  }

  writeValue(obj: any): void {
    this.form.setValue(obj);
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void { }

  setTypeahead() {
    this.searchSubscription = this.search$.pipe(debounceTime(500)).subscribe((keyword) => {
      this.keywordChange.emit(keyword ? keyword : '');
    });
  }
}
