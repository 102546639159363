import { Component, forwardRef, Input, OnDestroy, OnInit } from '@angular/core';
import { ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Subscription } from 'rxjs';
import { LabelMode } from 'utils/label-mode.enum';
import { GenerateRandom } from 'utils/random';

@Component({
  selector: 'app-monthpicker',
  templateUrl: './monthpicker.component.html',
  styleUrls: ['./monthpicker.component.scss'],
  providers: [{ provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => MonthpickerComponent), multi: true }],
})
export class MonthpickerComponent implements OnInit, OnDestroy, ControlValueAccessor {

  subscription: Subscription;

  @Input()
  set isDisabled(value: boolean) {
    if (value) {
      this.form.disable();
    } else {
      this.form.enable();
    }
  }

  @Input()
  set error(value: any) {
    this.isError = false;
    if (value) {
      this.isError = true;
    }
  }

  @Input() name = GenerateRandom(6);
  @Input() label: string;
  @Input() addonLabel: string = '';
  @Input() placeholder = '';
  @Input() errorMessage: string = 'VALIDATOR.REQUIRED';
  @Input() format: string = 'MMM yyyy';
  @Input() nzMode: string = 'month';
  @Input() disabledDate;
  @Input() classes: string;

  @Input() set labelMode(value: LabelMode) {
    this.mode = value;
  }

  LabelMode = LabelMode;
  mode: LabelMode = LabelMode.DEFAULT;

  form = new FormControl();

  isError = false;

  onChange = (value: string) => { };
  onTouched = (value: string) => { };

  constructor() { }

  ngOnInit(): void {
    this.subscription = this.form.valueChanges.subscribe(value => {
      this.onChange(value);
    })
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  writeValue(obj: any): void {
    this.form.setValue(obj);
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void { }
}
