import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuardService } from './services/_guard/auth-guard.service';
import { PermissionGuardService } from './services/_guard/permission-guard.service';

const routes: Routes = [
  {
    path: 'auth',
    loadChildren: () => import('./pages/authentication/authentication.module').then((m) => m.AuthenticationModule),
  },
  {
    path: '',
    canActivate: [AuthGuardService, PermissionGuardService],
    children: [
      {
        path: 'dashboard',
        loadChildren: () => import('./pages/dashboard/dashboard.module').then((m) => m.DashboardModule),
        canActivate: [AuthGuardService],
        data: { page_title: 'MENU.DASHBOARD' },
      },
      {
        path: 'appointment',
        loadChildren: () => import('./pages/appointment/appointment.module').then((m) => m.AppointmentModule),
        canActivate: [AuthGuardService],
        data: { page_title: 'MENU.APPOINTMENT' },
      },
      {
        path: 'medical-record',
        loadChildren: () => import('./pages/medical-record/medical-record.module').then((m) => m.MedicalRecordModule),
        canActivate: [AuthGuardService],
      },
      {
        path: 'reports',
        loadChildren: () => import('./pages/reports/reports.module').then((m) => m.ReportsModule),
        canActivate: [AuthGuardService],
      },
      {
        path: 'settings',
        loadChildren: () => import('./pages/settings/settings.module').then((m) => m.SettingsModule),
        canActivate: [AuthGuardService],
      },
      {
        path: 'pos',
        loadChildren: () => import('./pages/pos/pos.module').then((m) => m.PosModule),
        canActivate: [AuthGuardService],
      },
      { path: '', redirectTo: 'dashboard', pathMatch: 'full' },
    ]
  },
  {
    path: 'misc',
    loadChildren: () => import('./pages/miscellaneous/miscellaneous.module').then((m) => m.MiscellaneousModule),
  },
  {
    path: '',
    redirectTo: '/dashboard',
    pathMatch: 'full',
  },
  {
    path: '**',
    redirectTo: '/dashboard',
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      scrollPositionRestoration: 'enabled',
      relativeLinkResolution: 'legacy',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule { }
