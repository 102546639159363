<ng-container [ngSwitch]="mode">
  <ng-container *ngSwitchCase="LabelMode.DEFAULT" [ngTemplateOutlet]="default"></ng-container>
  <ng-container *ngSwitchCase="LabelMode.INLINE" [ngTemplateOutlet]="inline"></ng-container>
  <ng-container *ngSwitchCase="LabelMode.NO_LABEL" [ngTemplateOutlet]="input"></ng-container>
</ng-container>

<ng-template #input>
  <textarea #textareaBox class="form-control" [ngClass]="{ 'is-invalid': isError }" [id]="name" [name]="name" [placeholder]="placeholder | translate" [formControl]="form" [rows]="rows"></textarea>
</ng-template>

<ng-template #default>
  <div class="form-group" [ngClass]="classes">
    <label [for]="name" [ngClass]="{ 'is-invalid': isError }">{{ label | translate }} <ng-container [ngTemplateOutlet]="subLabel"></ng-container></label>
    <ng-container [ngTemplateOutlet]="input"></ng-container>
  </div>
</ng-template>

<ng-template #inline>
  <div class="form-group form-group-inline">
    <label [for]="name" [ngClass]="{ 'is-invalid': isError }">{{ label | translate }} <ng-container [ngTemplateOutlet]="subLabel"></ng-container></label>
    <ng-container [ngTemplateOutlet]="input"></ng-container>
  </div>
</ng-template>

<ng-template #subLabel>
  <span ngClass="{{addonLabelClass}}" *ngIf="addonLabel != '' && addonLabel != '*'">({{ addonLabel | translate }})</span>
  <span ngClass="{{addonLabelClass}}" *ngIf="addonLabel != '' && addonLabel === '*'">{{ addonLabel | translate }}</span>
</ng-template>