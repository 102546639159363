<div class="info-list" [ngClass]="classes">
  <label [for]="name">{{ label | translate }} <span *ngIf="subLabel">({{ subLabel | translate }})</span></label>
  <ng-container [ngTemplateOutlet]="info"></ng-container>
</div>

<ng-template #info>
  <p class="data" *ngIf="data">
    <ng-container [ngTemplateOutlet]="dataDisplay"></ng-container>
    <ng-container [ngTemplateOutlet]="subDataDisplay"></ng-container>
  </p>
  <p class="data text-muted" *ngIf="!data">
    <ng-container [ngTemplateOutlet]="noDataDisplay"></ng-container>
  </p>
</ng-template>

<ng-template #dataDisplay>
  <ng-container *ngIf="!html; else htmlEl">
    <ng-container *ngIf="!decimals; else decimalsEl">
      <ng-container *ngIf="!mask; else maskEl">{{ data }}</ng-container>
    </ng-container>
  </ng-container>

  <ng-template #htmlEl>
    <span [innerHtml]="data" class="pre-line"></span>
  </ng-template>

  <ng-template #decimalsEl>
    {{ data | number: '1.'+decimals+'-'+decimals }}
  </ng-template>

  <ng-template #maskEl>
    {{ data | mask: '' + mask }}
  </ng-template>
</ng-template>

<ng-template #subDataDisplay>
  <span *ngIf="subData">({{ subData }} {{ dataUnit | translate }})</span>
  <span *ngIf="suffix"> {{ suffix | translate }}</span>
</ng-template>

<ng-template #noDataDisplay>
  <span *ngIf="placeholder">{{ placeholder }}</span>
  <span *ngIf="!placeholder">- {{ 'APP.NO_DATA' | translate }} -</span>
</ng-template>