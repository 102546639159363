import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';
import { SCREEN_SIZE } from 'utils/screen-size.enum';

@Injectable({
  providedIn: 'root'
})
export class ScreenResizeService {

  screen_size = {
    xs: 0,
    sm: 576,
    md: 768,
    lg: 992,
    xl: 1200,
    xxl: 1440
  }

  get onResize$(): Observable<any> {
    return this.resizeSubject.asObservable().pipe(distinctUntilChanged());
  }

  private resizeSubject: Subject<any>;
  public currentSize: any;

  constructor() {
    this.resizeSubject = new Subject();
  }

  onResize(value) {
    let screenSize;
    if (value.width > this.screen_size.xs && value.width < this.screen_size.sm) {
      screenSize = SCREEN_SIZE.XS
    } else if (value.width >= this.screen_size.sm && value.width < this.screen_size.md) {
      screenSize = SCREEN_SIZE.SM
    } else if (value.width >= this.screen_size.md && value.width < this.screen_size.lg) {
      screenSize = SCREEN_SIZE.MD
    } else if (value.width >= this.screen_size.lg && value.width < this.screen_size.xl) {
      screenSize = SCREEN_SIZE.LG
    } else if (value.width >= this.screen_size.xl && value.width < this.screen_size.xxl) {
      screenSize = SCREEN_SIZE.XL
    } else if (value.width >= this.screen_size.xxl) {
      screenSize = SCREEN_SIZE.XXL
    }
    this.currentSize = screenSize;
    localStorage.setItem('initScrSize', JSON.stringify(screenSize));
    this.resizeSubject.next(screenSize);
  }
}
