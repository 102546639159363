import { Component, Input, OnInit } from '@angular/core';
import { GenerateRandom } from 'utils/random';

@Component({
  selector: 'info-item',
  templateUrl: './info-item.component.html',
  styleUrls: ['./info-item.component.scss']
})
export class InfoItemComponent implements OnInit {
  @Input() name = GenerateRandom(6);
  @Input() label: string;
  @Input() subLabel: string;
  @Input() data: any;
  @Input() subData: any;
  @Input() suffix: any;
  @Input() decimals: number = 0;
  @Input() dataUnit: string;
  @Input() placeholder: string;
  @Input() classes: string;
  @Input() html: boolean = false;
  @Input() mask: string;

  constructor() { }

  ngOnInit(): void {
  }
}
