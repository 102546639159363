import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable } from 'rxjs';
import { retry } from 'rxjs/operators';
import { User } from './user';
import { UserRoleId } from 'utils/role.enum';

const TOKEN_KEY = 'token';
const AUTH_KEY = 'auth-user';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  public currentToken: string;
  public currentUser: Observable<User>;

  private currentUserSubject: BehaviorSubject<User>;

  constructor(private http$: HttpClient, private router: Router) {
    this.getToken();
    this.getAuthUser();
  }

  getToken() {
    if (this.currentToken) {
      return this.currentToken;
    }
    this.currentToken = localStorage.getItem(TOKEN_KEY);
    return this.currentToken;
  }

  getAuthUser() {
    this.currentUserSubject = new BehaviorSubject<User>(JSON.parse(localStorage.getItem(AUTH_KEY)));
    this.currentUser = this.currentUserSubject.asObservable();
  }

  public get currentUserValue(): User {
    return this.currentUserSubject.value;
  }

  isAuthenticated() {
    return this.currentToken && this.currentUserValue ? true : false;
  }

  setAccessUser(response: any) {
    this.currentToken = response.access_token;
    this.currentUser = response.user;

    if (response.access_token) {
      response.user.clinic_id = response.clinic_id;
      response.user.role.permission_json = Object.values(response.user.role.permission_json);
      localStorage.setItem(TOKEN_KEY, response.access_token);
      localStorage.setItem(AUTH_KEY, JSON.stringify(response.user));
      this.currentUserSubject.next(response.user);
    } else {
      localStorage.removeItem(TOKEN_KEY);
      localStorage.removeItem(AUTH_KEY);
      this.currentUserSubject.next(null);
    }
  }

  getPermission() {
    let permissions = this.currentUserValue.role.permission_json;

    if ([UserRoleId.SYSTEM_ADMIN, UserRoleId.ADMIN].includes(this.currentUserValue.role_id)) {
      permissions.push({
        name: "permission",
        permissions: [
          { name: 'read', is_active: true },
          // { name: 'create', is_active: false },
          { name: 'update', is_active: true },
          // { name: 'delete', is_active: false },
        ],
      })
    }

    return permissions;
  }

  login(params: any) {
    return this.http$.post('/sessions', params).pipe(retry(1));
  }

  logout() {
    this.setAccessUser({ access_token: null });
    this.router.navigateByUrl('/auth');
  }
}
