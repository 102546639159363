import { Component, EventEmitter, forwardRef, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Subject, Subscription } from 'rxjs';
import { distinctUntilChanged, takeUntil } from 'rxjs/operators';
import { GenerateRandom } from 'utils/random';

@Component({
  selector: 'app-number-touchspin',
  templateUrl: './number-touchspin.component.html',
  styleUrls: ['./number-touchspin.component.scss'],
  providers: [{ provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => NumberTouchspinComponent), multi: true }],
})
export class NumberTouchspinComponent implements OnInit, OnDestroy, ControlValueAccessor {

  subscription: Subscription;

  @Input()
  set isDisabled(value: boolean) {
    this.disabled = value;
    if (value) {
      this.form.disable();
    } else {
      this.form.enable();
    }
  }

  @Input() name = GenerateRandom(6);
  @Input() iconChevron = false;
  @Input() size: string = '';
  @Input() color: string = '';
  @Input() stepValue: number;
  @Input() maxValue: number = 9999;
  @Input() minValue: number = 0;
  @Input() inputClass: string;
  @Input() classes: string;

  @Output() valueChange = new EventEmitter();

  form = new FormControl(0);

  public disabledValueIncrement = false;
  public disabledValueDecrement = false;

  public disabled = false;

  onChange = (value: string) => { };
  onTouched = (value: string) => { };

  constructor() { }

  ngOnInit(): void {
    this.subscription = this.form.valueChanges.subscribe(value => {
      if (value == this.maxValue || value > this.maxValue) {
        this.disabledValueIncrement = true;
      } else {
        this.disabledValueIncrement = false;
      }
      if (value == this.minValue || value < this.minValue) {
        this.disabledValueDecrement = true;
      } else {
        this.disabledValueDecrement = false;
      }

      this.valueChange.emit(value);
      this.onChange(value);
    })
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  writeValue(obj: any): void {
    this.form.setValue(obj);

    if (obj === this.minValue) {
      this.disabledValueDecrement = true;
    } else if (obj === this.maxValue) {
      this.disabledValueIncrement = true;
    }
  }

  increment() {
    let currentValue = Number(this.form.value);
    if (this.stepValue == undefined) {
      this.form.setValue(currentValue += 1);
    } else {
      this.form.setValue(currentValue += this.stepValue);
    }

    if (!(this.minValue == undefined || this.maxValue == undefined)) {
      if (this.form.value == this.maxValue || this.form.value > this.maxValue) {
        this.disabledValueIncrement = true;
      } else {
        this.disabledValueIncrement = false;
      }
      if (this.form.value > this.minValue) {
        this.disabledValueDecrement = false;
      } else {
        this.disabledValueDecrement = true;
      }
    }
  }

  decrement() {
    let currentValue = Number(this.form.value);
    if (this.stepValue == undefined) {
      this.form.setValue(currentValue -= 1);
    } else {
      this.form.setValue(currentValue -= this.stepValue);
    }

    if (!(this.minValue == undefined || this.maxValue == undefined)) {
      if (this.form.value == this.minValue || this.form.value < this.minValue) {
        this.disabledValueDecrement = true;
      } else {
        this.disabledValueDecrement = false;
      }
      if (this.form.value < this.maxValue) {
        this.disabledValueIncrement = false;
      } else {
        this.disabledValueIncrement = true;
      }
    }
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void { }
}
