<ng-container [ngSwitch]="mode">
  <ng-container *ngSwitchCase="LabelMode.DEFAULT" [ngTemplateOutlet]="default"></ng-container>
  <ng-container *ngSwitchCase="LabelMode.INLINE" [ngTemplateOutlet]="inline"></ng-container>
  <ng-container *ngSwitchCase="LabelMode.NO_LABEL" [ngTemplateOutlet]="input"></ng-container>
</ng-container>

<ng-template #input>
  <div class="input-group datepicker-group" [ngClass]="{'error': isError, 'require': isRequire}">
    <input ngbDatepicker #d="ngbDatepicker" class="form-control" [name]="name" [placeholder]="placeholder | translate" [formControl]="form" [firstDayOfWeek]="7" [minDate]="minPDate" [maxDate]="maxPDate">
    <div class="input-group-append">
      <span class="input-group-text cursor-pointer" (click)="d.toggle()" [ngClass]="{'disabled': form.disabled}">
        <span [data-feather]="'calendar'" [size]="17"></span>
      </span>
    </div>
  </div>
  <!-- <div>
    <nz-date-picker name="basicDate" [ngClass]="{'is-invalid': isError}" [formControl]="form" [nzFormat]="format" [nzPlaceHolder]="placeholder | translate" [nzDisabledDate]="disabledDate" [nzShowToday]="showToday"></nz-date-picker>
  </div> -->
  <ng-container [ngTemplateOutlet]="error"></ng-container>
</ng-template>

<ng-template #default>
  <div class="form-group" [ngClass]="classes">
    <label [for]="name">{{ label | translate }} <span *ngIf="isRequire" class="text-danger">*</span> <ng-container [ngTemplateOutlet]="subLabel"></ng-container></label>
    <ng-container [ngTemplateOutlet]="input"></ng-container>
  </div>
</ng-template>

<ng-template #inline>
  <div class="form-group form-group-inline" [ngClass]="classes">
    <label [for]="name">{{ label | translate }} <span *ngIf="isRequire" class="text-danger">*</span> <ng-container [ngTemplateOutlet]="subLabel"></ng-container></label>
    <ng-container [ngTemplateOutlet]="input"></ng-container>
  </div>
</ng-template>

<ng-template #error>
  <span class="error" *ngIf="isError">{{ errorMessage | translate }}</span>
</ng-template>

<ng-template #subLabel>
  <span class="text-muted" *ngIf="addonLabel != ''">({{ addonLabel | translate }})</span>
</ng-template>