import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { CoreDirectivesModule } from '@core/directives/directives';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
import { NgxMaskModule } from 'ngx-mask';

import { NzDatePickerModule } from 'ng-zorro-antd/date-picker';

import { InputComponent } from './input/input.component';
import { DatatableComponent } from './datatable/datatable.component';
import { InputWSelectComponent } from './input-w-select/input-w-select.component';
import { DatepickerComponent } from './datepicker/datepicker.component';
import { SelectComponent } from './select/select.component';
import { RadioComponent } from './radio/radio.component';
import { CheckboxComponent } from './checkbox/checkbox.component';
import { InfoItemComponent } from './info-item/info-item.component';
import { DaterangePickerComponent } from './daterange-picker/daterange-picker.component';
import { TextareaComponent } from './textarea/textarea.component';
import { NumberTouchspinComponent } from './number-touchspin/number-touchspin.component';
import { ScreenSizeDetectorComponent } from './screen-size-detector/screen-size-detector.component';
import { ImageUploadComponent } from './image-upload/image-upload.component';
import { NgxFileDropModule } from 'ngx-file-drop';
import { StatusLoadingComponent } from './status-loading/status-loading.component';
import { NgbDatepickerModule, NgbTimepickerModule } from '@ng-bootstrap/ng-bootstrap';
import { TimepickerComponent } from './timepicker/timepicker.component';
import { ReportCardComponent } from './report-card/report-card.component';
import { MonthpickerComponent } from './monthpicker/monthpicker.component';
import { FileUploadMultupleComponent } from './file-upload-multuple/file-upload-multuple.component';
import { DashboardCardComponent } from './dashboard-card/dashboard-card.component';

@NgModule({
  declarations: [
    InputComponent,
    DatatableComponent,
    InputWSelectComponent,
    DatepickerComponent,
    SelectComponent,
    RadioComponent,
    CheckboxComponent,
    InfoItemComponent,
    DaterangePickerComponent,
    TextareaComponent,
    NumberTouchspinComponent,
    ScreenSizeDetectorComponent,
    ImageUploadComponent,
    StatusLoadingComponent,
    TimepickerComponent,
    ReportCardComponent,
    MonthpickerComponent,
    FileUploadMultupleComponent,
    DashboardCardComponent,
  ],
  imports: [
    CommonModule,
    TranslateModule,
    NgxDatatableModule,
    CoreDirectivesModule,
    FormsModule,
    ReactiveFormsModule,
    NgSelectModule,
    NgxMaskModule.forRoot(),
    NgxFileDropModule,
    NgbTimepickerModule,
    NgbDatepickerModule,
    NzDatePickerModule,
  ],
  exports: [
    InputComponent,
    DatatableComponent,
    InputWSelectComponent,
    DatepickerComponent,
    SelectComponent,
    RadioComponent,
    CheckboxComponent,
    InfoItemComponent,
    DaterangePickerComponent,
    TextareaComponent,
    NumberTouchspinComponent,
    ScreenSizeDetectorComponent,
    ImageUploadComponent,
    StatusLoadingComponent,
    TimepickerComponent,
    ReportCardComponent,
    MonthpickerComponent,
    FileUploadMultupleComponent,
    DashboardCardComponent,
  ],
  providers: [],
})
export class ComponentsModule {}
