<!-- Menu header -->
<div class="navbar-header d-flex align-items-center">
  <ul class="nav navbar-nav flex-row align-items-center w-100">
    <li class="nav-item mr-auto">
      <!-- App Branding -->
      <a class="navbar-brand" [routerLink]="['/']" [ngClass]="{'ml-25 mr-0': isCollapsed}">
        <span class="brand-logo">
          <img [src]="isCollapsed ? coreConfig.app.appLogoImage : coreConfig.app.appLogoImageFull" alt="brand-logo" height="34" />
        </span>
      </a>
    </li>

    <!-- Menu Toggler -->
    <li class="nav-item nav-toggle">
      <a class="nav-link modern-nav-toggle d-none d-xl-block p-0" (click)="toggleSidebarCollapsible()">
        <i [ngClass]="isCollapsed === true ? 'icon-toggle-left' : 'icon-toggle-right'" class="toggle-icon feather font-medium-4 collapse-toggle-icon text-primary"></i>
      </a>
      <a class="nav-link modern-nav-toggle d-flex d-xl-none pr-0" (click)="toggleSidebar()">
        <i data-feather="x" class="font-medium-4 text-primary toggle-icon"></i>
      </a>
    </li>
  </ul>
</div>
<!--/ Menu header -->

<!-- Navbar shadow -->
<div class="shadow-bottom" [ngClass]="{ 'd-block': isScrolled }"></div>

<!-- Main menu -->

<div class="main-menu-content">
  <ng-scrollbar visibility="hover" externalContentWrapper=".navigation-main">
    <ul class="navigation navigation-main" layout="vertical" core-menu></ul>
  </ng-scrollbar>

  <!--  -->
  <div class="side-bar-footer mt-auto mb-1 mx-1" *ngIf="!isCollapsed">
    <!-- <div class="card mb-0 p-1 manual no-shadow">
      <p class="mb-0">{{ 'MISC.MANUAL_MSG' | translate }} </p>
    </div> -->
    <div class="font-small-2 mt-75 text-secondary">
      <p class="text-center mb-0 line-height-sm">{{ environment?.env }}</p>
      <p class="text-center mb-0 line-height-sm">Version {{ environment?.version }} &nbsp;|&nbsp; © 2024 Eclin</p>
    </div>
  </div>
</div>
<!--/ Main menu -->