<ng-container [ngSwitch]="mode">
  <ng-container *ngSwitchCase="LabelMode.DEFAULT" [ngTemplateOutlet]="default"></ng-container>
  <ng-container *ngSwitchCase="LabelMode.INLINE" [ngTemplateOutlet]="inline"></ng-container>
  <ng-container *ngSwitchCase="LabelMode.NO_LABEL" [ngTemplateOutlet]="input"></ng-container>
</ng-container>

<ng-template #input>
  <ngb-timepicker [name]="name" [formControl]="form" [spinners]="spinners" [ngClass]="{'error': isError, 'require': isRequire}"></ngb-timepicker>
  <ng-container [ngTemplateOutlet]="error"></ng-container>
</ng-template>

<ng-template #default>
  <div class="form-group" [ngClass]="classes">
    <label [for]="name">{{ label | translate }} <span *ngIf="isRequire" class="text-danger">*</span></label>
    <ng-container [ngTemplateOutlet]="input"></ng-container>
  </div>
</ng-template>

<ng-template #inline>
  <div class="form-group form-group-inline" [ngClass]="classes">
    <label [for]="name">{{ label | translate }} <span *ngIf="isRequire" class="text-danger">*</span></label>
    <ng-container [ngTemplateOutlet]="input"></ng-container>
  </div>
</ng-template>

<ng-template #error>
  <span class="error" *ngIf="isError">{{ errorMessage | translate }}</span>
</ng-template>