import { Injectable } from '@angular/core';
import { NgxPermissionsService } from 'ngx-permissions';

interface Permission {
  name: string;
  permissions;
}

@Injectable({
  providedIn: 'root',
})
export class PermissionService {

  constructor(private permissionsService: NgxPermissionsService) { }

  initPermission(payload: { permissions: Permission[]; }) {
    let perm = [];
    for (const item of payload.permissions) {
      for (const permission of item.permissions) {
        if (permission.is_active) {
          perm.push(`${item.name}:${permission.name}`);
        }
      }
    }
    this.permissionsService.loadPermissions(perm);
    // console.log(this.permissionsService.getPermissions());
  }
}
