import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'status-loading',
  templateUrl: './status-loading.component.html',
  styleUrls: ['./status-loading.component.scss']
})
export class StatusLoadingComponent implements OnInit {

  @Input() isLoading: boolean = true;
  @Input() classes: string;

  constructor() { }

  ngOnInit(): void {
  }

}
